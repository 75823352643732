<template>
    <div>
        <div class="zozo-main-wrapper">
            <div id="main" class="main-section">
                <!-- <div style="height:200px">
                    <div data-zozo-parallax="2"
                        data-zozo-parallax-image="http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/about-header-bg.jpg"
                        data-zozo-parallax-position="left top"
                        class="page-title-section page-titletype-default page-titleskin-default page-titlealign-center page-title-image-bg zozo-parallax">
                        <div class="page-title-wrapper clearfix">
                            <div class="container">
                                <div class="page-title-container">
                                    <div class="page-title-captions"> 
                                        <h1 class="entry-title" style="font-family: 'Cairo' !important;"> {{ $t("contact") }}</h1>
                                        <div class="page-title-breadcrumbs">
                                            <div id="breadcrumb" class="breadcrumb zozo-breadcrumbs"><a
                                                    href="http://themes.zozothemes.com/charity/">{{ $t("home") }}</a> <span
                                                    class="current">{{ $t("contact") }}</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="zozo_parallax-inner" data-center="top: 0%;" data-bottom-top="top: 0%;"
                            data-top-bottom="top: -100%;"
                            style="height: 200%; background-image: url(&quot;http://themes.zozothemes.com/charity/wp-content/uploads/sites/9/2017/03/about-header-bg.jpg&quot;); bottom: 0px;">
                        </div>
                    </div>
                </div> -->

                <div data-zozo-parallax="2" data-zozo-parallax-position="left top"
                    class="page-title-section page-titletype-default page-titleskin-default page-titlealign-default zozo-parallax"
                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                    <div class="page-title-wrapper clearfix">
                        <div class="container">
                            <div class="page-title-container">
                                <div class="page-title-captions">
                                    <h1 class="entry-title " style="font-family: 'Cairo' !important;">
                                        {{ $t("contact") }}</h1>
                                </div>
                                <div class="page-title-breadcrumbs">
                                    <div id="breadcrumb" class=""
                                        v-bind:class="[{'text_en' : $i18n.locale=='ar' },{'text_Ar':$i18n.locale=='en'}]">
                                        <router-link to="/" style="font-family: 'Cairo' !important;">
                                            {{ $t("header.home") }}</router-link>
                                        <i class="fas fa-angle-left" style="margin-left:5px;margin-right:5px"></i>
                                        <span class="current"
                                            style="font-family: 'Cairo' !important;">{{ $t("contact") }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="zozo_parallax-inner" data-center="top: 0%;" data-bottom-top="top: 0%;"
                        data-top-bottom="top: -100%;" style="height: 200%; bottom: 0px;"></div>
                </div>

                <div id="fullwidth" class="main-fullwidth main-col-full">
                    <section class="vc_row wpb_row vc_row-fluid vc_custom_1491215920432 vc-zozo-section typo-default"
                        style="padding: 36px !important">


                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="container">
                                <div class="row">
                                    <div
                                        class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="vc_row wpb_row vc_inner vc_row-fluid">
                                                    <div class="zozo-vc-row-inner vc-inner-row-section clearfix">
                                                        <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-yes  clearfix"
                                                                        id="feature-box-1">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-large grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper no-hover shape-icon-circle">
                                                                                    <i
                                                                                        class="fas fa-map-marker grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-light pattern-1 icon-large fa-sm"></i>
                                                                                </div>
                                                                                <h4 class="grid-title-below grid-title "
                                                                                    style="font-family: 'Cairo' !important;">
                                                                                    {{ $t("address") }}</h4>
                                                                                <div class="grid-desc">
                                                                                    <h6
                                                                                        style="font-family: 'Cairo' !important;">
                                                                                        {{ $t("loc_address") }}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-yes  clearfix"
                                                                        id="feature-box-2">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-large grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper no-hover shape-icon-circle">
                                                                                    <i
                                                                                        class="fas fa-phone grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-light pattern-1 icon-large"></i>
                                                                                </div>
                                                                                <h4 style="font-family: 'Cairo' !important;"
                                                                                    class="grid-title-below grid-title ">
                                                                                    {{ $t("phone") }}</h4>
                                                                                <div class="grid-desc">
                                                                                    <h6>Support: +964 0772 410 0065</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="wpb_column vc_column_inner vc_column_container vc_col-sm-4 typo-default">
                                                            <div class="vc_column-inner ">
                                                                <div class="wpb_wrapper">
                                                                    <div class="zozo-feature-box feature-box-style style-default-box style-sep-yes  clearfix"
                                                                        id="feature-box-3">
                                                                        <div class="grid-item">
                                                                            <div
                                                                                class="grid-box-inner grid-text-center grid-box-large grid-box-icon-circle grid-icon-shape grid-shape-none">
                                                                                <div
                                                                                    class="grid-icon-wrapper no-hover shape-icon-circle">
                                                                                    <i
                                                                                        class="fa fa-envelope grid-icon zozo-icon icon-shape icon-circle icon-skin-default icon-light pattern-1 icon-large"></i>
                                                                                </div>
                                                                                <h4 style="font-family: 'Cairo' !important;"
                                                                                    class="grid-title-below grid-title ">
                                                                                    {{ $t("email") }}</h4>
                                                                                <div class="grid-desc">
                                                                                    <h6>info@abnakr.org
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="vc_row wpb_row vc_row-fluid vc_custom_1491215930064 vc-zozo-section typo-default">
                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="wpb_column vc_main_column vc_column_container vc_col-sm-12 typo-default">
                                <div class="vc_column-inner ">
                                    <div class="wpb_wrapper">
                                        <div
                                            class="vc_separator wpb_content_element vc_separator_align_center vc_sep_width_100 vc_sep_pos_align_center vc_separator_no_text vc_sep_color_grey">
                                            <span class="vc_sep_holder vc_sep_holder_l"><span
                                                    class="vc_sep_line"></span></span><span
                                                class="vc_sep_holder vc_sep_holder_r"><span
                                                    class="vc_sep_line"></span></span></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section class="vc_row wpb_row vc_row-fluid vc_custom_1491215938045 vc-zozo-section typo-default">
                        <div class="zozo-vc-main-row-inner vc-normal-section">
                            <div class="container">
                                <div class="row"
                                    v-bind:class="[{'row_ar' : $i18n.locale=='ar' },{'row_en':$i18n.locale=='en'}]">
                                    <div class="wpb_column vc_main_column vc_column_container vc_col-sm-4 typo-default"
                                        v-bind:class="[{'con_men_ar' : $i18n.locale=='ar' },{'con_men_en':$i18n.locale=='en'}]">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div role="form" class="wpcf7" id="wpcf7-f803-p789-o1" lang="en-US"
                                                    dir="ltr">
                                                    <div class="screen-reader-response">
                                                        <p role="status" aria-live="polite" aria-atomic="true"></p>
                                                        <ul></ul>
                                                    </div>
                                                    <form action="/charity/contact/#wpcf7-f803-p789-o1" method="post"
                                                        class="wpcf7-form init" novalidate="novalidate"
                                                        data-status="init">

                                                        <div style="display: none;"> <input type="hidden" name="_wpcf7"
                                                                value="803"> <input type="hidden" name="_wpcf7_version"
                                                                value="5.4.2"> <input type="hidden" name="_wpcf7_locale"
                                                                value="en_US"> <input type="hidden"
                                                                name="_wpcf7_unit_tag" value="wpcf7-f803-p789-o1">
                                                            <input type="hidden" name="_wpcf7_container_post"
                                                                value="789"> <input type="hidden"
                                                                v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                name="_wpcf7_posted_data_hash" value=""> <input
                                                                style="border: 1px solid;" type="hidden"
                                                                name="_wpcf7_recaptcha_response"
                                                                value="03AGdBq26jUCeCWu6pE_X-xZ-fD4uA9vvkDIFM-U2mcAwkKUJN78gGXtN0Mcr0yC_bLiSQ-DRqvYXdNfQ-kE8ZkLSVZVN07YocGYXMGkXiU7Btohwqn4y5lGSkjLEtlMDGhka-_06rfNeeZcDRjkC8IW_eOYFQyafeAzfXefBQpSS8v32wPJuawXxqbcKER6sC3ZvMoJhWOEym2JAddcCLrwn7CDMelKNv1m5IFmlI4paEIL4p--hsDm9N1uiEkV1Z8-og9-gcFnURsp6r6U_HLlaG0kjvHSK9fFaOoa50IztheLN7ge3EXFxtntIh-bryUXzFYF4DpKUUSwn4nAclhw6exkqnIxQLY3rh_nROi4v-kAySK2jCUvFVDnPZ5P_IrEXB6ugEiC9yJldO8qSJaUuxR1bA6SiX-wRmk8wHkxDv7SpSOya1Ij-mbIraCID6ZG7Yx923iKx6ntnESUHFrmfQzoC1dCllOA">
                                                        </div>
                                                        <p><span class="wpcf7-form-control-wrap your-name"><input
                                                                    type="text" name="your-name" value="" size="40"
                                                                    v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                                                                    aria-required="true" aria-invalid="false"
                                                                     v-model="editedItem.name" v-validate="'required|email'" :rules="nameRules" 
                                                                    style="border: 1px solid #dfdfdf;"
                                                                    :placeholder="$t('name')"></span></p>
                                                        <p> <span class="wpcf7-form-control-wrap your-email"><input
                                                                    type="email" name="your-email" value="" size="40"
                                                                    
                                                                    v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                                                                    aria-required="true" aria-invalid="false"
                                                                    style="border: 1px solid #dfdfdf;"
                                                                    :rules="emailRules"  v-model="editedItem.email"
                                                                    :placeholder="$t('email')"></span></p>
                                                        <!-- <p> <span class="wpcf7-form-control-wrap tel-265"><input
                                                                    type="tel" name="tel-265" value="" size="40"
                                                                    style="border: 1px solid #dfdfdf;"
                                                                    v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                    class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-tel"
                                                                    aria-invalid="false"
                                                                    :placeholder="$t('phone')"></span></p> -->
                                                        <p> <span class="wpcf7-form-control-wrap your-message"><textarea
                                                                    name="your-message" cols="40" rows="10"
                                                                    style="border: 1px solid #dfdfdf;"
                                                                    class="wpcf7-form-control wpcf7-textarea"
                                                                    :rules="comRules" required v-model="editedItem.message"
                                                                    v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                                    aria-invalid="false"
                                                                    :placeholder="$t('message')"></textarea></span></p>
                                                        <p> 
                                                            
                                                            
                                                           <v-btn
      depressed
      color="green"
      @click="submit()"

    >
      ارسال
    </v-btn>
                                                                <span
                                                                class="ajax-loader"></span></p> <input type="hidden"
                                                            v-bind:class="[{'text_Ar' : $i18n.locale=='ar' },{'text_en':$i18n.locale=='en'}]"
                                                            id="ct_checkjs_cf7_1f50893f80d6830d62765ffad7721742"
                                                            name="ct_checkjs_cf7" value="0">
                                                        <div class="wpcf7-response-output" aria-hidden="true"></div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="wpb_column vc_main_column vc_column_container vc_col-sm-8 typo-default">
                                        <div class="vc_column-inner ">
                                            <div class="wpb_wrapper">
                                                <div class="wpb_gmaps_widget wpb_content_element">
                                                    <div class="wpb_wrapper">
                                                        <div class="wpb_map_wraper">

                                                             <gmap-map id="googleMap" :center="center" :zoom="15"
                                                    style="width:100%;  height: 400px;" @click.native="setPlace"
                                                    :clickable="true" :draggable="true">
                                                    <gmap-marker :key="index" v-for="(m, index) in markers"
                                                        :position="m.position" @click="center=m.position">
                                                    </gmap-marker>
                                                </gmap-map>

                                                                
                                                                
                                                                
                                                                </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    </div>
</template>

<style>
    .con_men_en {
        float: left !important;
    }

    .con_men_ar {
        float: right !important;
    }

    .submit_ar {
        float: right !important;
    }

    .submit_en {
        float: left !important;
    }
</style>

<script>
 import Axios from "axios";
    export default {
        data() {
            return {
                 editedItem: {},
                     center: {
                lat: 32.5792815,
                lng: 44.0337525
            },
            markers: [],
            places: [],
            lat: 32.5792815,
            lng: 44.0337525,
            currentPlace: null,
                  validate: false,
                             nameRules: [
                    (v) => !!v || 'يجب ادخال الاسم',

                ],
                    comRules: [
                    (v) => !!v || 'يجب ادخال الرساله',

                ],
                        emailRules: [
        
      
          v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'الايميل غير صحيح'
      ],
            }
        },


          mounted() {

            const marker = {
                lat: parseFloat(this.lat),
                lng: parseFloat(this.lng)
            };

            this.markers.push({
                position: marker
            });
            this.places.push(this.position);
            this.center = marker;
            this.currentPlace = null;


            this.lat = this.getlocationposition.coords.latitude;
            this.lng = this.position.coords.longitude;

        },
        methods: {
              submit() {

                // if (this.$refs.form.validate()) {


                    Axios.post('/messsages', {

                        name: this.editedItem.name,
                      //  phone: this.editedItem.phone,
                        email: this.editedItem.email,
                        message: this.editedItem.message,


                    }).then(() => {
this.editedItem={}
                        this.dialog = false;
                        this.$swal.fire({
                                title: "تم الارسال" ,
                                text: "",
                                icon: "success",
                                confirmButtonText: "اغلاق",
                            });

                        this.close();

                    }).catch(() => {


                    });


               // }


            },
        },
    }
</script>